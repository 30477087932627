
import React, { useState, useEffect } from "react";
import { Container } from 'react-bootstrap';
import "animate.css/animate.css";
import ReactMarkdown from "react-markdown/with-html"
import "animate.css/animate.css";
// import ScrollAnimation from 'react-animate-on-scroll';

const AboutSection = (props)=>{
	// const [animation,setAnimation] = useState(false)
  useEffect(()=>{

		function isInViewport(el) {
		    let top = el.offsetTop;
  			let left = el.offsetLeft;
  			let width = el.offsetWidth;
  			let height = el.offsetHeight;

			  while(el.offsetParent) {
			    el = el.offsetParent;
			    top += el.offsetTop;
			    left += el.offsetLeft;
			  }

			  return (
			    top < (window.pageYOffset + window.innerHeight) &&
			    left < (window.pageXOffset + window.innerWidth) &&
			    (top + height) > window.pageYOffset &&
			    (left + width) > window.pageXOffset
			  );

		}

		const box = document.querySelector('.about-text');
		document.addEventListener('scroll', handleScroll, {
		    passive: true
		});

		function handleScroll () {
		
  			if(isInViewport(box)) {
  		    
          /** Here one can write animate.css class for animation **/

  				box.classList.add('animate__fadeInUp','animate__slower');  

  			} else {
  				
          box.classList.remove('animate__fadeInUp','animate__slower');
  		
  			}

		}

		return () =>{
			document.addEventListener('scroll', handleScroll);
		}

	},[])
  return (<React.Fragment>
  
  
      <div className='about-text'>
	  {/* <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true} > */}
        <Container className="rowContent" >
          <div className="head-sec text-center  textc"  >
            <span className="sub-title">{props.Title}</span>
            <ReactMarkdown source={props.Content} escapeHtml={false}/>            
          </div>          
        </Container>
		{/* {props.pageName === "Value" ?
		<div class="btn-wrap">
			<a href="/contact/our-offices/london-offices/" class="btn btn-success mb-4">Contact us</a>
		</div> : ""} */}
		{/* </ScrollAnimation> */}
      </div>
      
  </React.Fragment>)

}

export default AboutSection;