import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag";
import { Container, Col, Row, Form } from 'react-bootstrap';
import { Logo } from '../components/icon';

const GET_NEWS_MENUS = gql`

query GetNewsMenus {
  newsCategories (sort:"Sorting:asc") {
    Name
    URL
    Secondary_URL 
  }
}
`;

function NewsMenus(props) {
if (typeof window !== "undefined") {
    var pageURL = window.location.href ? window.location.href : '';
    var removeSlash = pageURL.replace(/^(.+?)\/*?$/, "$1");
    var lastURLSegment = removeSlash.substr(removeSlash.lastIndexOf('/') + 1);
}

const { loading:newsmenuloading, error:newsmenuerror, data:newsmenu } = useQuery(GET_NEWS_MENUS);

  if (newsmenuloading) return (
    <div className="static-details gql-loading-screen">
        <Container>
        <Logo />
        <div className="gql-loading">Loading ...</div>
        </Container>
    </div>);
    
  return (
  	<>

     <ul className="category-list d-flex">
			<li>
				<Link className={props.active} to="/about-us/stories/">all</Link>
			</li>
			{newsmenu.newsCategories.map((news, index, active) => {
			return (
			<li>
      {news.Secondary_URL ? <a target="_blank" className = {(lastURLSegment == news.URL) ? "active" : ""} href={news.Secondary_URL}>{news.Name}</a> :
			<Link className = {(lastURLSegment == news.URL) ? "active" : ""} to={`/about-us/stories/${news.URL}/`}>{news.Name}</Link>
      }
			</li>
			)
			})}
							
		</ul>
		</>
    );
}


export default NewsMenus