import React from "react";
import { Link } from "gatsby";
import { Button, Container, Row, Col, Form, Breadcrumb } from 'react-bootstrap';
import BannerImg from "../images/banner-img2.jpg";
import BannerImgTab from "../images/banner-img2-tab.jpg";
import BannerImgMb from "../images/banner-img2-mb.jpg";
import DateFormat from "../components/format-date";
import Breadcrumbs from "../components/breadcrumbs";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router"
import Img from "gatsby-image"

const InnerBanner = (props) => {
  const location = useLocation();
  const thePath = location.pathname
  const PageSlug = thePath.substring(thePath.lastIndexOf('/') + 1) + ' '
  const [showVideo, setShowVideo] = React.useState(false);
  return (

    <section className="banner-details">

      {thePath == '/property-services/sell/sellers-guide/' &&
        <Helmet>
          <script type="application/ld+json">
            {`{
              "@context": "https://schema.org/", 
              "@type": "HowTo", 
              "name": "Guide to Selling Property in London",
              "description": "Our guide to selling your home is comprehensive and provides you with useful tips for selling your home. The process of selling a house is easy and quick with Orlando Reid.",
              "image": "https://ggfx-orlandoreid.s3.eu-west-2.amazonaws.com/i.prod/12725658_1613370173524_c1793f6a2518_43598d10fa.jpg",
              "totalTime": "PT2M",
              "step": [{
                "@type": "HowToStep",
                "text": "It is advisable to ask a minimum of 3 agents to provide a valuation of your home. Try to choose both independent agents and corporate agents. A typical valuation will take approximately 30-60 minutes. You may notice that values provided by agents differ. It is important to do your own research on the market in current conditions. You should be guided by the final sale prices of other properties in the area rather than by the asking prices, as asking prices can be set at anything a vendor sees fit. It is important that the property is priced realistically in order to achieve the highest selling price. Asking prices set above market value can conversely negatively impact the final price achieved.
            It is important to ask the agent the following questions:
            What are their contract length and notice period?
            What are their fees?
            What is their strategy for selling the property?
            It is important that you have a mutual understanding and rapport with your chosen agent-as it is likely that you will be in regular contact with them over the upcoming months.",
                "image": "https://ggfx-orlandoreid.s3.eu-west-2.amazonaws.com/i.prod/12725658_1613370173524_c1793f6a2518_43598d10fa.jpg",
                "name": "Choosing an agent:",
                "url": "https://www.orlandoreid.co.uk/property-services/sell/sellers-guide#chooseanagent"
              },{
                "@type": "HowToStep",
                "text": "We would advise you to pick a reputable solicitor with positive client feedback. It is important to ensure that your solicitor is working at a comparable speed to you and the rest of the chain, as dyssynchrony in this regard can compromise the purchase of the property. Any set timescales should be adhered to and worked towards. The conveyancing process is one of the more arduous tasks in a transaction and often can be a sticking point for a sale to progress. A reputable solicitor is worth investing in, to give you the highest chance of seeing the selling process through to completion.     
            Although, strictly speaking, a solicitor’s work begins once you have found a buyer, it is worth liaising with a solicitor and having someone in place as early in the process as possible. This gives you time to provide them with the required (sometimes copious) documentation required, early on in the process. Often if it is left too close to the point where an offer is accepted. If you are selling a leasehold property, it is helpful to order a management pack from the freeholder prior to finding a buyer. This can take some weeks to arrive and is required by the solicitor. Give yourself that seller’s advantage by having all your paperwork in place at the time the offer is accepted!",
                "image": "https://ggfx-orlandoreid.s3.eu-west-2.amazonaws.com/i.prod/12725658_1613370173524_c1793f6a2518_43598d10fa.jpg",
                "name": "Choosing your solicitor:",
                "url": "https://www.orlandoreid.co.uk/property-services/sell/sellers-guide#choosingyoursolicitor"
              },{
                "@type": "HowToStep",
                "text": "This is an exciting time! The property is on the market and viewings will be starting shortly. It is important to ensure that the property is tidy and well presented for viewings, so that it can be shown in the best possible light. We personally recommend that the owner is not present on viewings. The owner’s presence can unnerve buyers, shorten the viewing time, and impact the honesty of buyers’ feedback. Buyers need time and space to make informed decisions on whether to proceed with any further interest, so it is important to allow the agent adequate time and access for viewings.",
                "image": "https://ggfx-orlandoreid.s3.eu-west-2.amazonaws.com/i.prod/12725658_1613370173524_c1793f6a2518_43598d10fa.jpg",
                "name": "Viewings:",
                "url": "https://www.orlandoreid.co.uk/property-services/sell/sellers-guide#viewing"
              },{
                "@type": "HowToStep",
                "text": "You now have an offer on the table. It is important that your agent is aware of the buyer’s position, including their financial situation and any potential financiers (parents, inheritance etc.) A buyer should also be financially qualified by an independent financial broker if they require a mortgage. This is to make sure they can afford the amount they are offering.
            It is important to be clear on the following points:
            What are the buyers’ ideal timescales? If you are in a chain, there may need to be some negotiation on what is expected of them.
            What is their level of deposit?
            Are they having an independent survey on top of the mortgage survey?
            If they are selling a property, how far along are they with the sale? Is their part of the chain complete? They will need to provide the chain details so that your agent can verify this.
            If they are funded by a third party (e.g. parents), have that third party viewed the property? This is very important so as not to get weeks into the sale, for a third party to reject the purchase.
            It is not always the case that cash buyers are preferable over buyers requiring a mortgage. Cash buyers have less financial commitment throughout the conveyancing process (e.g. with items such as mortgage surveys). A buyer with a good-size deposit may be just as good a candidate- they will have had to pay for surveys and will therefore be more committed to the sale process. A cash buyer may be preferable in a situation in which you have achieved a price above market value. In this situation, a bank may choose not to lend the required amount to a buyer with a mortgage.",
                "image": "https://ggfx-orlandoreid.s3.eu-west-2.amazonaws.com/i.prod/12725658_1613370173524_c1793f6a2518_43598d10fa.jpg",
                "name": "Offers:",
                "url": "https://www.orlandoreid.co.uk/property-services/sell/sellers-guide#offers"
              },{
                "@type": "HowToStep",
                "text": "Once the offer has been accepted, the process of conveyancing begins. The agent will act as your advocate and will liaise with both parties’ solicitors, ensuring that work is being completed in a timely fashion and relevant questions and queries are addressed.
            During this part of the sale, it is important to ensure that the agent instructs the buyer on the following points:
            The buyer should instruct their solicitor
            They need to progress their mortgage agreement in principle to a formal mortgage application. It is important to get the mortgage survey
            They need to ensure that the mortgage survey and independent survey have been booked in within a reasonable timescale (approximately 10 working days.)",
                "image": "https://ggfx-orlandoreid.s3.eu-west-2.amazonaws.com/i.prod/12725658_1613370173524_c1793f6a2518_43598d10fa.jpg",
                "name": "Conveyancing:",
                "url": "https://www.orlandoreid.co.uk/property-services/sell/sellers-guide#conveyancing"
              },{
                "@type": "HowToStep",
                "text": "Once all enquiries have been answered, deposits will be sent to solicitors and a completion date will be agreed upon. Your solicitor will call you for permission to exchange contracts. This will mean the deal and sale are legally binding. Then it is just a case of waiting for the completion day to come!",
                "image": "https://ggfx-orlandoreid.s3.eu-west-2.amazonaws.com/i.prod/12725658_1613370173524_c1793f6a2518_43598d10fa.jpg",
                "name": "Exchange and Completion:",
                "url": "https://www.orlandoreid.co.uk/property-services/sell/sellers-guide#exchangeandcompletion"
              },{
                "@type": "HowToStep",
                "text": "Gazumping – When a buyer tries to offer more than the amount currently accepted from your chosen buyer. This is generally frowned upon, but all offers must be put forward up until contracts are exchanged.
            EPC – Energy performance certificate. This is needed by law before your property can go onto the market. It is valid for 10 years and often there is already one in place if you bought post 2010. You can check on the national database www.epcregister.com
            Homebuyers report – This is the report provided to the buyer if they are having an independent survey. It is most commonly undertaken on flats. House buyers will have a more detailed structural survey carried out.
            Mortgage offer – Once your buyer has had a satisfactory mortgage survey carried out, the bank will send out a document to the client to let them know how much they are willing to lend on the property. This needs to be in place before the exchange of contracts can take place.",
                "image": "https://ggfx-orlandoreid.s3.eu-west-2.amazonaws.com/i.prod/12725658_1613370173524_c1793f6a2518_43598d10fa.jpg",
                "name": "Jargon buster:",
                "url": "https://www.orlandoreid.co.uk/property-services/sell/sellers-guide#jargonbuster"
              }]            
            }`}
          </script>
        </Helmet>
      }

      {thePath == '/property-services/buy/buyers-guide/' &&
        <Helmet>
          <script type="application/ld+json">
            {`{
               "@context": "https://schema.org/", 
               "@type": "HowTo", 
               "name": "Guide to Buying Property in London",
               "description": "The process of buying a house is challenging. Our guide to buying a house has information you need including the steps to buying a house quickly and easily.",
               "image": "https://ggfx-orlandoreid.s3.eu-west-2.amazonaws.com/i.prod/12725658_1613370173524_c1793f6a2518_43598d10fa.jpg",
               "totalTime": "PT2M",
               "step": [{
                 "@type": "HowToStep",
                 "text": "This should be the first point to consider, even prior to deciding on an area. We would strongly advise using a broker rather than going directly to a bank, even if you have an existing relationship with that bank. A mortgage broker will be able to review a range of options from a variety of lenders to suit your personal situation, and often save you money.  
             You should try to avoid paying a broker “up-front” as this can be detrimental to the quality of their service to you further down the line. There are excellent brokers who will not request a fee until completion and this is often only a small admin fee.",
                 "image": "https://ggfx-orlandoreid.s3.eu-west-2.amazonaws.com/i.prod/12725658_1613370173524_c1793f6a2518_43598d10fa.jpg",
                 "name": "How much can I afford?",
                 "url": "https://www.orlandoreid.co.uk/property-services/buy/buyers-guide#howmuchcaniafford"
               },{
                 "@type": "HowToStep",
                 "text": "Having consulted with a broker regarding your borrowing potential now having the ability to get an “agreement in principle”, you should register with local agents in the area(s) of interest to you. It is helpful to have an agreement in principle prior to speaking to an agent, as they can immediately start searching for the perfect property for you, informing you immediately when a property that fits your criteria comes to market. They may even call you before a property is advertised on the market, to help avoid competition.
             At the point of registration with an agent, it is worth having compiled a list of your essential requirements for your property search and, conversely, a list of requirements that are desirable, but you would be willing to sacrifice. We know that most buyers will not find the “perfect property” so having this list is key to keeping your wishes at the centre of our search. It is also a good idea to register with marketing websites such as Rightmove. Sign up to their “alerts” and you will receive real-time notifications when a property of your specification in your chosen area comes to market.",
                 "image": "https://ggfx-orlandoreid.s3.eu-west-2.amazonaws.com/i.prod/12725658_1613370173524_c1793f6a2518_43598d10fa.jpg",
                 "name": "When should I register with an agent?",
                 "url": "https://www.orlandoreid.co.uk/property-services/buy/buyers-guide#whenshoudliregisterwithanagent"
               },{
                 "@type": "HowToStep",
                 "text": "Below are a few essential questions you should ask the agent:
             Flats:
             Is the property leasehold or share of freehold? (see jargon buster at the end)
             If it is leasehold what is the unexpired lease length?
             What are the charges? (ground rent, service charge and buildings insurance)
             Are there any upcoming works scheduled for the building?
             Who is the freeholder?
             Do you know anything about the neighbours?
             Is there a chain?
             Houses:
             Is there a chain?
             Who are the neighbours?
             Have there been any works carried out on the house in the past few years?
             When booking a viewing with your agent, find out how much time they have allocated for the viewing. If you don’t you may find that their diaries are booked on a very tight schedule which may only give you 5 to 10 minutes to look around a property. Request more time if you need to.",
                 "image": "https://ggfx-orlandoreid.s3.eu-west-2.amazonaws.com/i.prod/12725658_1613370173524_c1793f6a2518_43598d10fa.jpg",
                 "name": "What questions should I ask when viewing a property?",
                 "url": "https://www.orlandoreid.co.uk/property-services/buy/buyers-guide#whatquestionsshouldiaskwhenviewingaproperty"
               },{
                 "@type": "HowToStep",
                 "text": "We recommend providing as much detail as possible with your offer, including the current financial situation, level of deposit and ideal completion dates, as this will show intent to the agent and vendor. We can help through this process and can provide you with templates within which to propose your offer.",
                 "image": "https://ggfx-orlandoreid.s3.eu-west-2.amazonaws.com/i.prod/12725658_1613370173524_c1793f6a2518_43598d10fa.jpg",
                 "name": "What do I need to consider when making an offer?",
                 "url": "https://www.orlandoreid.co.uk/property-services/buy/buyers-guide#whatdoineedtoconsiderwhenmakinganoffer"
               },{
                 "@type": "HowToStep",
                 "text": "We would advise you to pick a reputable solicitor with positive client feedback. It is important to ensure that your solicitor is working a comparable speed to you and the rest of the chain, as dyssynchrony in this regard can compromise the purchase of the property. Any set timescales should be adhered to and worked towards. The conveyancing process is one of the more arduous tasks in a transaction and often can be a sticking point for a sale to progress. A reputable solicitor is worth investing in, to give you the highest chance of seeing the buying process through to completion.
             It is worth liaising with a solicitor and having someone in place as early in the process as possible. This gives you time to provide them with the required (sometimes copious) documentation required, early on in the process. Often if it is left too close to the point where an offer is accepted. Give yourself that buyer’s advantage by having all your paperwork in place at the time the offer is accepted!",
                 "image": "https://ggfx-orlandoreid.s3.eu-west-2.amazonaws.com/i.prod/12725658_1613370173524_c1793f6a2518_43598d10fa.jpg",
                 "name": "How do I go about choosing a solicitor?",
                 "url": "https://www.orlandoreid.co.uk/property-services/buy/buyers-guide#howdoigoaboutchoosingasolicitor"
               },{
                 "@type": "HowToStep",
                 "text": "This is an exciting time! However, the buying process is not yet over.
             Below, we have listed what is expected of you as the buyer at this point:
             You will need to inform your solicitor of the offer acceptance
             You will need to provide your solicitor details to your agent. The agent will provide all parties with “Memorandums of sale” documents. The vendor’s solicitor will provide initial papers to your solicitor and the conveyancing process will be underway
             You will need to liaise with your mortgage broker to convert your mortgage in principle to an official mortgage application. We recommend doing this as soon as possible, so that a mortgage survey can be booked in a timely manner, to prevent any pressure from the vendor.
             You may want to appoint an independent surveyor to carry out any additional surveys you require. On a flat, a homebuyers report will suffice. On a house, a full structural survey is advisable.
             Once all surveys are back and satisfactory, then it is over to the solicitors to carry out their process through to completion. Don’t expect this part of the process to be easy. If you are buying a period property, then be prepared to see little points on the survey results and quite often damp. This is due to the age of the property.
             The main advice at this point is to keep the pressure on your solicitor so that you are in a position to exchange contracts at the agreed time.",
                 "image": "https://ggfx-orlandoreid.s3.eu-west-2.amazonaws.com/i.prod/12725658_1613370173524_c1793f6a2518_43598d10fa.jpg",
                 "name": "What happens once my offer has been accepted?",
                 "url": "https://www.orlandoreid.co.uk/property-services/buy/buyers-guide#whathappenoncemyofferhasbeenaccepted"
               },{
                 "@type": "HowToStep",
                 "text": "You are now in a position to exchange contracts.
             To facilitate this, the below items have to be fulfilled up and down the chain:
             All enquiries have been answered satisfactorily
             The mortgage offer is in with the solicitor or your cash funds are available
             All searches are back with your solicitor
             Signed contracts and the deposit are back with your solicitor
             A date for completion has to be agreed upon prior to the exchange of contracts
             Once contracts have been exchanged, the purchase deal is legally binding and “set in stone.” You must now wait for the completion day. You will get a call from the solicitor to inform you that you are able to collect the keys from the agent. Ensure you leave enough time to celebrate the purchase of your new home!",
                 "image": "https://ggfx-orlandoreid.s3.eu-west-2.amazonaws.com/i.prod/12725658_1613370173524_c1793f6a2518_43598d10fa.jpg",
                 "name": "What needs to happen to exchange contracts and achieve completion?",
                 "url": "https://www.orlandoreid.co.uk/property-services/buy/buyers-guide#whatneedstohappentoexchangecontracts"
               },{
                 "@type": "HowToStep",
                 "text": "Gazumping
             When a buyer tries to offer more than the amount currently accepted from the chosen buyer. This is generally frowned upon, but all offers must be put forward up until contracts are exchanged.
             EPC
             Energy performance certificate. This is required by law before your property can go onto the market. It is valid for 10 years and often there is already one in place if you bought post 2010. You can check on the national database www.epcregister.com
             Homebuyers report
             This is the report provided to the buyer if they are having an independent survey. It is most commonly undertaken on flats. House buyers will have a more detailed structural survey carried out.
             Mortgage offer
             Once a satisfactory mortgage survey has been carried out, the bank will send out a document to the buyer to let them know how much they are willing to lend on the property. This needs to be in place before the exchange of contracts can take place.
             Leasehold
             Flats will often be “leasehold”. This means that you are effectively leasing the land from a separate party, the freeholder. It is your legal right after 2 years of owning the flat to extend the lease. This will come at a cost depending on the length of lease you have left. For more information, the following website offers guidance via their calculator: http://www.lease-advice.org/calculator/
             New leases will start at a nominal length, most commonly 99 years or 125 years. Once a lease falls below a “marriage value” at 80 years, it becomes significantly more costly to extend the lease. It is advisable to check these points out before offering on a property. Extending a lease can be a lengthy process and can take months.
             Leasehold properties will have a management company responsible for the upkeep of the building and maintenance. Part of the service charge will go towards this. You will also pay ground rent to the freeholder.
             Share of freehold
             Flats can also be “share of freehold”. This means that the owners of each flat in a building are sharing and looking after the freehold between them. There will still be an underlying lease. This is much easier to extend and will normally cost a small solicitors fee to carry out. The management of the building may be organised in a variety of ways. Freeholders may set up a company between them and appoint themselves as directors. The co-freeholders may then pay into a joint fund (often called a sinking fund) where they build up a pot of money for any unexpected or planned works or it may be that issues are dealt with on an ad-hoc basis, without such a fund in place. This works well in a building where the majority of the freeholders reside in the building, but less well in buildings where most units are rented out, as it can result in communal areas running into disrepair. 
             Freeholder
             Most houses will be freehold. In this case, the proprietor will be the resident and freeholder. In the case of a leasehold flat, the freeholder will be an individual or company.
             Service charge
             On a leasehold property there will be a service charge paid to the freeholder or management company. This will be paid to cover maintenance of the building and general upkeep. If there is a concierge or lift in the building, these service costs tend to increase. Often these costs will also cover building insurance.  
             Ground rent
             Rent paid to the owner of the land (the freeholder.)
             Under offer
             A term used by agents to signify that a property has had an offer accepted on it, but contracts have not yet been exchanged.",
                 "image": "https://ggfx-orlandoreid.s3.eu-west-2.amazonaws.com/i.prod/12725658_1613370173524_c1793f6a2518_43598d10fa.jpg",
                 "name": "Jargon Buster, Tips & Tricks:",
                 "url": "https://www.orlandoreid.co.uk/property-services/buy/buyers-guide#Jargonbuster"
               }]                       
            }`}
          </script>
        </Helmet>
      }

      {thePath == '/property-services/rent/tenant-guide/' &&
        <Helmet>
          <script type="application/ld+json">
            {`{
              "@context": "https://schema.org/", 
              "@type": "HowTo", 
              "name": "Guide to Renting in London",
              "description": "A guide for tenants renting through Orlando Reid.",
              "image": "https://ggfx-orlandoreid.s3.eu-west-2.amazonaws.com/i.prod/12725658_1613370173524_c1793f6a2518_43598d10fa.jpg",
              "totalTime": "PT2M",
              "step": [{
                "@type": "HowToStep",
                "text": "There are several costs involved in renting a property.
            In 2020, the Tenant Fees Act 2019, came into full force meaning many of the fees once paid by the tenant, are now not payable. There are however some upfront costs involved before renting a property.
            - Holding Deposit (equal to 1 week's rent)
            - Tenancy Security Deposit (equal to 5 week's rent where the rent is less than £50,000 p/a and equal to 6 weeks rent where the rent is above this)
            - First month’s rent
            At the heart of your search should be your budget. If you have a monthly rental price in mind, make sure you check added costs including utility bills.
            To save time when you start looking, make sure you have your identification documentation, immigration status and proof of address easily to hand.",
                "image": "https://ggfx-orlandoreid.s3.eu-west-2.amazonaws.com/i.prod/12725658_1613370173524_c1793f6a2518_43598d10fa.jpg",
                "name": "Step 1: Make sure you have your finances and documents prepared",
                "url": "https://www.orlandoreid.co.uk/property-services/rent/tenant-guide#makesureyouhaveyourfinancesanddocumentsprepared"
              },{
                "@type": "HowToStep",
                "text": "Most agents will have an income criteria as part of the referencing, and Orlando Reid require you to earn 2.5* the gross rent as a simple calculation.
            It may be more affordable to add people you want to live as it will reduce the individual monthly costs.
            Properties on the rental market can often be available to view around six to eight weeks before they are ready, so work to this timescale.
            Start your search as soon as you know you need to move, up to 2 months prior.
            Most agents will register you as an applicant and keep you updated on new properties during this time.
            You can begin your property search with your agent online, over the phone or in the branch, but once you've discussed your needs and the agent narrows down the shortlist, the agent should then arrange a viewing appointment for you whether in person or via video tour.
            Use our rental search and adjust the filters and advanced features to narrow down the search to find exactly what you want, where you want.
            Sign-up Orlando Reid and you can save any property you like the look of to view and compare later on. When you progress to the viewings and offer stage, you'll be able to track the progress of your rental here too.",
                "image": "https://ggfx-orlandoreid.s3.eu-west-2.amazonaws.com/i.prod/12725658_1613370173524_c1793f6a2518_43598d10fa.jpg",
                "name": "Step 2: Finding the right property for you",
                "url": "https://www.orlandoreid.co.uk/property-services/rent/tenant-guide#findingtherightproperty"
              },{
                "@type": "HowToStep",
                "text": "The rental market moves fast so if you see the perfect property with an Agent. You have to act quickly to secure it.  Arrange a viewing, or multiple viewings as soon as you can and try to view properties in quick succession so it’s easier to make a decision. Orlando Reid can offer virtual viewings in first instance using our 3D matterport technology before viewing the property in person.
            Once you have found the right flat or house, you’ll need to put a holding deposit down to state your commitment to the property.",
                "image": "https://ggfx-orlandoreid.s3.eu-west-2.amazonaws.com/i.prod/12725658_1613370173524_c1793f6a2518_43598d10fa.jpg",
                "name": "Step 3: Start viewing!",
                "url": "https://www.orlandoreid.co.uk/property-services/rent/tenant-guide#startviewing"
              },{
                "@type": "HowToStep",
                "text": "You will be asked to give references to your prospective landlord/agent to confirm your details and background. Most agents will ask you to complete the details of your references online. Please make sure you give plenty of contact details so that the process is quick.  It’s also worth speaking with your references/referees so they know they will be approached.
            The typical referencing criteria consist of:
            - Three years' address history
            - Landlord details for any property rented in the last three years
            - Confirmation of Salary.
            - No adverse credit in the form of a county court judgement, bankruptcy order or similar
            - If you are self-employed, either an accountant's reference or evidence on the last three years' tax returns
            A Tenancy Agreement is a legally binding document that sets out the terms of the tenancy and is drawn up to ensure that the rights and obligations of both tenant and Landlord are adhered to. 
            Once the deposit is paid in full along with the first month’s rent and the tenancy is fully signed, the property is secured, and keys can be released to you.
            A tenancy deposit is held to give your Landlord protection in case you leave without paying the rent; cause damage to the property and / or its contents or you breach a term of the tenancy. Your tenancy agreement will show clearly what the deposit is for and how it will be dealt with under the terms of the Tenancy Deposit Scheme laws, according to what type of Tenancy you have.  You will be informed which government approved scheme is being used by your Agent/Landlord and you should get a confirmation once the deposit is correctly registered, this should happen within 30 days of you paying it.",
                "image": "https://ggfx-orlandoreid.s3.eu-west-2.amazonaws.com/i.prod/12725658_1613370173524_c1793f6a2518_43598d10fa.jpg",
                "name": "Step 4: Tenancy Application",
                "url": "https://www.orlandoreid.co.uk/property-services/rent/tenant-guide#tenancyapplication"
              },{
                "@type": "HowToStep",
                "text": "On your move-in date, there are several factors you need to look out for.
            All tenants/ guarantors to sign the Tenancy Agreement which can be done electronically using Orlando Reid’s E signature tools.
            Your agent can then hand over a copy of the Tenancy Agreement for your records along with the contact details of who you deal with going forward -this may be the Agent or the Landlord.  They will normally also provide you with a copy of the Gas Safety Certificate (if applicable), EPC, How to Rent Guide/Rent Smart Wales leaflet as a minimum.  From 1st July 2020, you should also receive a copy of an Electrical Safety Report.
            Future rent payments will normally be made monthly and paid by standing order – your agent will give you all the forms to complete or bank details, so you can set this up in time for the next rent due date.  If you are provided with a Payment Reference Number, ensure you use this so your rent can be found quickly once paid. Orlando Reid only accepts payments from one bank account for the full gross monthly rent.
            Your keys will be available to collect either at the local branch or at your check-in appointment from either the Landlord or inventory clerk; your local office will go through this with you beforehand and make the appointment.
            The inventory is a detailed list of the property contents and condition at the point that you move into it. By both parties agreeing that the inventory is correct at the start of the tenancy, any risk of disputes at the end of the tenancy will be minimised. We recommend therefore that you thoroughly check the inventory before you sign. This document is used when you move out of the property to so that any changes can be easily identified and charged for.
            You will also be given login details for your online account where there is safekeeping of important documents such as your tenancy agreement or deposit certificate.",
                "image": "https://ggfx-orlandoreid.s3.eu-west-2.amazonaws.com/i.prod/12725658_1613370173524_c1793f6a2518_43598d10fa.jpg",
                "name": "Step 5: Moving in",
                "url": "https://www.orlandoreid.co.uk/property-services/rent/tenant-guide#movingin"
              },{
                "@type": "HowToStep",
                "text": "If your property is managed by the Agent, you will be advised who to contact during your tenancy. They will discuss maintenance issues with you and whether action is needed either by yourself or the Landlord- depending on whether the issue is a long-term fault or through 'wear-and-tear'. Orlando Reid provides an online reporting tool for maintenance issues and an emergency contact phone number.
            If your property is managed by the Landlord, you will be advised of their name and contact details and you should contact them directly with any issues you may have.
            A property managed by the Agent will receive regular visits to the property. This will give the Agent an opportunity to check the property is being cared for on behalf of the Landlord – this will normally occur only a few times a year.  It is also an opportunity for Tenants to raise any queries during their time at the property.  Many Landlords do not live nearby so the Agent is often employed to ensure the property maintains in a good condition for you to live in.  If the Landlord manages the let themselves, they also may wish to visit periodically – both Landlords and Agents must give you proper written notice of when this may happen. Please note there also maybe property visits in the last two months of your tenancy for new prospective tenants to view.
            Towards the end of the fixed term of your contract, the agent or landlord will be in touch to find out what you would like to do - you will need to decide whether to extend your stay or move out. Make sure you get in touch so the agent/ landlord can action the necessary.",
                "image": "https://ggfx-orlandoreid.s3.eu-west-2.amazonaws.com/i.prod/12725658_1613370173524_c1793f6a2518_43598d10fa.jpg",
                "name": "Step 6: Living in your new property",
                "url": "https://www.orlandoreid.co.uk/property-services/rent/tenant-guide#livinginyournewproperty"
              },{
                "@type": "HowToStep",
                "text": "It’s best to let us know at least 2 months before the end of the fixed term that you may want to move out.  If Orlando Reid manages the property for the Landlord, we'll arrange a check-out appointment with you, so we can both ensure the property is left in an appropriate condition in accordance with the deposit legislation.  At this point, you will hand back the keys. If the Landlord manages the property, you will need to contact them directly to arrange a time and date to meet there, complete a checkout and hand the keys back.
            Once you know you are moving out and you have told the agent/ landlord, you'll need to make sure that the property is left in good condition for you to be entitled to your full deposit back. Most agents will confirm in writing to you certain requirements and tips in order that you can do this easily.  If there are no deductions or disputes and all the necessary forms are filled out correctly by you and by the Landlord, your deposit should be returned ten days later in accordance with the tenancy agreement.",
                "image": "https://ggfx-orlandoreid.s3.eu-west-2.amazonaws.com/i.prod/12725658_1613370173524_c1793f6a2518_43598d10fa.jpg",
                "name": "Step 7: Moving out of the property",
                "url": "https://www.orlandoreid.co.uk/property-services/rent/tenant-guide#movingoutoftheproperty"
              }]                        
            }`}
          </script>
        </Helmet>
      }

      {props.BannerImg &&
        <div className="details-banner-img">
          <picture>

            <Img fluid={props.BannerImg} alt={`${props.Title} banner - Orlando Reid`} />

          </picture>
        </div>
      }
      <div className="details-caption">
        <div className="container">
          <Row>
            <Col lg={8}>
              <Breadcrumbs alias={props.alias} detailname={props.Title} />
              <h1>
                {props.Title}
              </h1>
              <div className="d-flex align-items-center flex-wrap">
                {props.NewsDate != null &&
                  <span className="date">
                    <DateFormat date={props.NewsDate} />
                  </span>
                }

              </div>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  )
}
export default InnerBanner
