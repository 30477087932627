import React from "react"
import { useMatch } from "@reach/router"
import AllNews from './news-listings';
import GETCAT from './get-news-cat';
import NewsDetail from '../templates/news-details';

const App = (props) => {
  const match = useMatch('/about-us/stories/:item');
  const detailmatch = useMatch("/about-us/stories/:item/:slug")
  //console.log('aaaaaaaaa',detailmatch)
  if(detailmatch != null) {    
  	return(
    <GETCAT videodata={props.videodata} ifdetails={"news-details"} detailpageslug = {detailmatch.slug} detailpageitem={detailmatch.item}/>
  	)
  }
  if(match != null) {
  	return (
  	<GETCAT iflisting={"news-listing"}/>
  	)
  }
  if(detailmatch !=null && detailmatch.item == "news") {
    return(
    <NewsDetail videodata={props.videodata} caturl="news" detailpage = {detailmatch.slug} CategoryId="605300f673a0b824326b095b"/>
    )
  }
  if(match == null && detailmatch == null) {
  	return (
  	<AllNews/>
  	)
  }

}
export default App



