import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Breadcrumb, Form } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import NewsFilter from './news-filter';
import NewsDetail from '../templates/news-details';
import { Logo } from '../components/icon';


const GET_ALL_NEWS = gql`
  query GetNews ($URL: String!){
	  newsCategories (where:{URL: $URL}){
	    _id
	    Name
	    URL
	  }
  }
`;


function StaticNews(props) {
	if (typeof window !== "undefined") {
    var pageURL = window.location.href ? window.location.href : '';
    var removeSlash = pageURL.replace(/^(.+?)\/*?$/, "$1");
    var lastURLSegment = removeSlash.substr(removeSlash.lastIndexOf('/') + 1);
}
if(props.detailpageitem !=null) {
	var lastURLSegment= props.detailpageitem
}

//const { loading:recentnewsloading, error:recentnewserror, data:recentnews } = useQuery(GET_RECENT_NEWS);
const { loading:allnewsloading, error:allnewserror, data:allnews } = useQuery(GET_ALL_NEWS, {
    variables: { URL: lastURLSegment }
  });

  if (allnewsloading) return (
    <div className="static-details gql-loading-screen">
        <Container>
        <Logo />
        <div className="gql-loading">Loading ...</div>
        </Container>
	</div>);
	
  return (
  	<>


					{allnews.newsCategories.map((node, index) => {					
					if(index === 0) {
					return (
						<>
						{props.ifdetails == 'news-details' &&
						<NewsDetail caturl={node.URL} videodata={props.videodata} detailpage = {props.detailpageslug} CategoryId={node._id} />
						}
						{props.iflisting == 'news-listing' &&
						<NewsFilter CategoryName={node.Name} CategoryId={node._id}  />							
						}
						</>
					)
					}
					})}
					</>
    );
}


export default StaticNews