import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from 'react-bootstrap';
import "animate.css/animate.css";
import Counter from '../Counter/counter';
import ReactMarkdown from "react-markdown/with-html"
// import ScrollAnimation from 'react-animate-on-scroll';

const DataSection = (props)=>{
	const data = useStaticQuery(graphql`
		query getstates {
			strapiGlobalConfig {
				Global_Statistics {
					Statistics_Title
					Statistics_Description
					Stats {
					Value
					Value_Type
					Caption
					Decimal_Value
					Prefix
					}
				}
			}
		}
	`);
	const Global_Stats = data?.strapiGlobalConfig?.Global_Statistics;

	const [animation,setAnimation] = useState(false)
	useEffect(()=>{

		function isInViewport(el) {
		    let top = el.offsetTop;
  			let left = el.offsetLeft;
  			let width = el.offsetWidth;
  			let height = el.offsetHeight;

			  while(el.offsetParent) {
			    el = el.offsetParent;
			    top += el.offsetTop;
			    left += el.offsetLeft;
			  }

			  return (
			    top < (window.pageYOffset + window.innerHeight) &&
			    left < (window.pageXOffset + window.innerWidth) &&
			    (top + height) > window.pageYOffset &&
			    (left + width) > window.pageXOffset
			  );

		}

		const box = document.querySelector('.dataSec');
		document.addEventListener('scroll', handleScroll, {
		    passive: true
		});

		function handleScroll () {
		
  			if(isInViewport(box)) {
  		    
          /** Here one can write animate.css class for animation **/

  				box.classList.add('animate__fadeInUp','animate__slower');  

  			} else {
  				
          box.classList.remove('animate__fadeInUp','animate__slower');
  		
  			}
		}

		return () =>{
			document.addEventListener('scroll', handleScroll);
		}

	},[])

	return (<React.Fragment>
	
	
      <section className="section-data text-center data ">
	  	{/* <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true} > */}
          <Container className="dataSec">
            <span className="sub-title">{Global_Stats.Statistics_Title}</span>
            <ReactMarkdown source={Global_Stats.StatDesc} escapeHtml={false}/>

            <Row className="data-wrap">

            {Global_Stats.Stats.map((Stats, key) => {
              return <>
              <Col md="6" className="col-xl-3">
                {/* <strong>96%</strong> */}
                {Stats.Decimal_Value == false && 
                  <Counter
                    start={Stats.Value}
                    sym={Stats.Value_Type}
					prefix={Stats.Prefix}
                    duration={7}/>
                }
                
                {Stats.Decimal_Value == true && 
                  <Counter
                    start={Stats.Value}
                    sym={Stats.Value_Type}
					prefix={Stats.Prefix}
                    duration={7}
                    decimal={1} />
                }
                
                <span><ReactMarkdown source={Stats.Caption} escapeHtml={false}/></span>
              </Col>
              </>

              })}                    

            </Row>
          </Container>
		  {/* </ScrollAnimation> */}
        </section>
      
	</React.Fragment>)

}

export default DataSection;