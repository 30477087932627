import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Button, Container, Row, Col, Form } from 'react-bootstrap';
import Gallery1 from "../../images/gallery1.jpg";
import Fade from 'react-reveal/Fade';
import "animate.css/animate.css";
import Counter from '../Counter/counter';
import Gallery2 from "../../images/gallery2.jpg";
import DropDown from '../Dropdown/dropdown';
import Gallery3 from "../../images/floor.png";


const SectionArea = (props)=>{

  const [animation,setAnimation] = useState(false)
  const [myPropertyValue, setMypropertyValue] = useState(0);
  useEffect(()=>{

    function isInViewport(el) {
        let top = el.offsetTop;
        let left = el.offsetLeft;
        let width = el.offsetWidth;
        let height = el.offsetHeight;

        while(el.offsetParent) {
          el = el.offsetParent;
          top += el.offsetTop;
          left += el.offsetLeft;
        }

        return (
          top < (window.pageYOffset + window.innerHeight) &&
          left < (window.pageXOffset + window.innerWidth) &&
          (top + height) > window.pageYOffset &&
          (left + width) > window.pageXOffset
        );

    }
    const img  = document.querySelector('.imgarea');
    const text = document.querySelector('.textContarea');
    document.addEventListener('scroll', handleScroll, {
        passive: true
    });

    function handleScroll () {
    
      
       if(isInViewport(img)) {
          
          /** Here one can write animate.css class for animation **/
          img.classList.add('animate__fadeInLeft','animate__slower');
          
        } else {
          img.classList.remove('animate__fadeInLeft','animate__slower');
        
        }

        if(isInViewport(text)) {      
          /** Here one can write animate.css class for animation **/
          text.classList.add('animate__fadeInRight','animate__slower');
          
        } else {
          text.classList.remove('animate__fadeInRight','animate__slower');
        
        }
    }

    return () =>{
      document.addEventListener('scroll', handleScroll);
    }

  },[])

  return (<React.Fragment>
       <section className="section-area d-none d-md-block">
        <Container className="areaSec animate__animated">
          <Row className="align-items-lg-center">
            <Col lg="6" className="col-xl-7  animate__animated imgarea">
              <div className="vedio-card">
                <img src={Gallery3} alt="img" />
              </div>
            </Col>
            <Col lg="6" className="col-xl-5 animate__animated textContarea ">
              <div className="property-report our-area">
                <span className="sub-title">suitable for all sizes</span>
                
                <h2>
                Proin sed eleifend tellus. Mauris accumsan neque ullamcorper, consectetur ipsum ac. Fusce pulvinar pulvinar varius.</h2>
                <p>Fusce pulvinar pulvinar varius. Praesent iaculis commodo tortor at pharetra. Proin condimentum sollicitudin nisi non vulputate. Ut elementum dignissim odio, eget tempor velit commodo in. Duis iaculis imperdiet fringilla.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      
  </React.Fragment>)

}

export default SectionArea;