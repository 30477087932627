import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import Img4 from "../images/img4.jpg";
import Img5 from "../images/img5.jpg";
import Img6 from "../images/img6.jpg";
import axios from 'axios';
import "slick-carousel/slick/slick.css";
import ImageTransform from "./common/ggfx-client/module/components/image-transform";

import { Link } from "gatsby";
const ImageSlider = (props) => {
  const [propItems, setPropItems] = useState(false)
  const getitems = async url => {
    try {     
      const { data } = await axios.get(url, {
        headers: {
            Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`
          }    
        })
      setPropItems(data)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    let officeId = props.officeId ?? "";
    let area = props.area ?? "";
    let url = process.env.GATSBY_STRAPI_SRC + "/properties?search_type=lettings&_limit=3&_sort=price:DESC&publish=true"; // TODO: base URL supposed to be from .env

    if (area) {
      url = url + "&display_address_contains=" + area;
    }
    if (officeId) {
      url = url + "&office_crm_id=" + officeId;
    }

    getitems(url)
  },[])
    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        arrows: false,
        centerPadding: '240px',
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    centerPadding: '192px',
                }
            },
            {
                breakpoint: 1293,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    centerPadding: '140px',
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerPadding: '163px',
                    infinite: true,

                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    centerPadding: '0px',
                    dots: true,
                    infinite: true,

                }
            }
        ]
    };

    return (
        <div className="property-slider">
            <Slider {...settings}>
            {propItems && propItems.map((item, key) => {
            let processedImages = JSON.stringify({});
            if (item?.imagetransforms?.images_Transforms) {
                processedImages = item.imagetransforms.images_Transforms;
            }
            var checkptype = item.search_type ==="sales"?'/property-for-sale/' :'/property-to-rent/'
                return <div className="property-block text-center">
                    <Link to={`${checkptype}${item.slug}-${item.id}/`}>
                        <figure className="property-img">
                        <ImageTransform imagesources={item.images[0].url} renderer="srcSet" imagename="property.images.similarpropertytile"attr={{ alt: item.display_address+ ' - Orlando Reid' }}
                        imagetransformresult={processedImages} id={item.id} testparam={true} />
                        </figure>
                        <div className="property-title">
                            <h3>{item.display_address}</h3>
                            <span className="price-info">{item.price && '£' + item.price.toLocaleString()}</span>
                            <span className="room-info">{item.title}</span>
                        </div>
                    </Link>
                </div>
            })} 

            </Slider >
        </div>


    )

}

export default ImageSlider;