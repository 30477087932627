import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Breadcrumb, Form } from 'react-bootstrap';
import Header2 from "../components/Header2/header2";
import DateFormat from "../components/format-date";
import Breadcrumbs from "../components/breadcrumbs";
import NewsMenus from "../components/news-menus";
import NewsDetails from "../templates/news-details";
import $ from "jquery"
import Subscribe from "../images/subscribe.png";
import { useStaticQuery, graphql } from "gatsby"
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import NewsletterForm from "../components/forms/newsletter-form";
import ImageTransform from "../components/common/ggfx-client/module/components/image-transform";

import { Logo } from '../components/icon';


const GET_ALL_NEWS = gql`
  query GetNews ($_id: String!){	
	newsEvents  (sort:"News_Date:desc",where:{Select_Categories: [{_id:[ $_id] }]}) {
        Title
        URL
        id
        News_Date
		Select_Categories{
			URL
		}
		External_Link
		Banner_Image {
	      url
	    }
		Intro_Image {
	      url
	    }
		imagetransforms
      }
  }
`;


function StaticNews(props) {
	const { loading: allnewsloading, error: allnewserror, data: allnews } = useQuery(GET_ALL_NEWS, {
		variables: { _id: props.CategoryId }
	});

	if (allnewsloading) return (
		<div className="static-details gql-loading-screen">
			<Container>
				<Logo />
				<div className="gql-loading">Loading ...</div>
			</Container>
		</div>);

	return (
		<>

			<div className="sub-wrapper">
				{/* header start */}
				<Header2 />
				{/* header end */}
				{/* content start */}
				<div className="news-content">
					<Container>
						{/* news head start */}
						<div className="news-head text-center">
							<Breadcrumbs alias='stories' detailname={props.CategoryName}/>
							<h1>News, Insights and Video from Orlando Reid</h1>
							<NewsMenus />
						</div>
						{/* news head end */}
						{/* news banner start */}
						{allnews.newsEvents.map((node, index) => {
							let processedImages1 = JSON.stringify({});
							if (index === 0 && node.Select_Categories[0].URL !="press-release") {
								if (node?.imagetransforms?.Banner_Image_Transforms)
									processedImages1 = node.imagetransforms.Banner_Image_Transforms;
							let url = (node.URL != "" && node.URL != undefined) ? node.URL : null;
							return (
								<>
									{node.Banner_Image != null &&
										<div className="news-banner">

											<Link to={url && `${url}/`} params={{ testvalue: node.id }}>
												<img src={node.Banner_Image.url} alt={`${node.Title} - Orlando Reid`}/>												
												<div className="news-caption text-center">
													<DateFormat date={node.News_Date} />
													<h2>{node.Title}</h2>
												</div>
											</Link>
										</div>
									}
								</>
							)
						}
					})}
					{/* news banner end */}
						{/* property-wrap start */}
						<Row className="news-items-wrap">
							{allnews.newsEvents.map((item, index) => {
								let processedImages2 = JSON.stringify({});
								
								if (index != 0 && index <= 3 || item.Select_Categories[0].URL =="press-release") {
									if (item?.imagetransforms?.Intro_Image_Transforms) 
										processedImages2 = item.imagetransforms.Intro_Image_Transforms;									

									return (

										<Col md="6" className="col-lg-4">

											<div className="news-block text-center">
												<Link to={item.External_Link?item.External_Link:item.URL} target={item.External_Link?'_blank':''}>
													<figure className="news-img">
													<img src={item.Intro_Image.url} alt={`${item.Title} - Orlando Reid`}/>					
													</figure>
													<div className="news-title">
														{item.News_Date !=null &&
															<DateFormat date={item.News_Date} />
														}
														<h3>{item.Title}</h3>
													</div>
												</Link>
											</div>
										</Col>
									)
								}
							})}

						</Row>
						{/* property-wrap end */}
						{/* newsletter block start */}
						<Form className="newsletter-block banner-newsletter">
							<NewsletterForm />
							<div className="subscribe text-center d-md-flex align-items-md-center justify-content-md-center animate__animated">
								<img src={Subscribe} alt="img" />
								<span>
									<strong>Stay up to date </strong>
							with our new videos by
							<a href="https://www.youtube.com/channel/UCR0ob8Yam7B9tuG-81So3GA" target='_blank'> subscribing to our YouTube</a>
								</span>
							</div>
						</Form>
						{/* newsletter block end */}
						{/* property-wrap extend start */}
						<Row className="news-items-extend">
							{allnews.newsEvents.map((item, index) => {
								let processedImages = JSON.stringify({});
								if (item?.imagetransforms?.Intro_Image_Transforms) {
									processedImages = item.imagetransforms.Intro_Image_Transforms;
								}
								if (index != 0 && index >= 4) {
									return (
										<Col md="6" className="col-lg-4">
											<div className="news-block text-center">
												<Link to={`${item.URL}/`}>
													<figure className="news-img">
													<img src={item.Intro_Image.url} alt={`${item.Title} - Orlando Reid`}/>					
													</figure>
													<div className="news-title">
														<DateFormat date={item.News_Date} />
														<h3>{item.Title}</h3>
													</div>
												</Link>
											</div>
										</Col>
									)
								}
							})}
						</Row>
						{/* property-wrap extend end */}
					</Container>
				</div>
				{/* content end */}
				{/* footer start */}
				{/* footer end */}
			</div>
		</>
	);
}


export default StaticNews