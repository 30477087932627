import { Container, Navbar, Nav } from 'react-bootstrap';
import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { useLocation } from "@reach/router"

// import 'bootstrap/dist/css/bootstrap.min.css';
const GetCTA = (props) => {
  const location = useLocation();
  const thePath = location.pathname
  const PageSlug = thePath.substring(thePath.lastIndexOf('/') + 1) + ' '
  const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  
   const data = useStaticQuery(graphql`
    query ThumbnailQuery {  
    allStrapiAllMenus(sort: {fields: Sort, order: ASC}) {
        edges {
          node {
            Label
            URL
            Show_In_Burger_Menu
            Button_Class
            main_parent {
              Label
              URL
            }
            sub_parent {
              Label
              URL
            }
          }
        }
      }     
    }
  `)
    if(props.customlink !=null) {
      return(
      <Link to={`${props.customlink}/`} className={props.classname}>

<figure className="property-img">
  <Img fixed={props.img} imgStyle={{transition:"all 0.5s"}} alt={`${capitalize(PageSlug)}${props.Title} - Orlando Reid`} />
</figure>
<div className="property-title">
  <h3>{props.Title}</h3>
</div>
</Link>
      )
    }
    return (


      (props.link != null && 
        <>
        {data.allStrapiAllMenus.edges.map(({node}, key) => {
          return<>
          {node.main_parent === null && node.sub_parent === null && node.URL == props.link.URL &&
          <Link to={`/${props.link.URL}/`} className={props.classname}>

                  <figure className="property-img">
                    <Img fixed={props.img} imgStyle={{transition:"all 0.5s"}} alt={`${capitalize(PageSlug)}${props.Title} - Orlando Reid`}/>
                  </figure>
                  <div className="property-title">
                    <h3>{props.Title}</h3>
                  </div>
                  </Link>
          }
          {node.main_parent != null && node.sub_parent === null && node.URL == props.link.URL &&
          <Link to={`/${node.main_parent.URL}/${props.link.URL}/`} className={props.classname}>
                  <figure className="property-img">
                    <Img fixed={props.img} imgStyle={{transition:"all 0.5s"}} alt={`${capitalize(PageSlug)}${props.Title} - Orlando Reid`}/>
                  </figure>
                  <div className="property-title">
                    <h3>{props.Title}</h3>
                  </div>
          </Link>
          }
          {node.main_parent != null && node.sub_parent != null && node.URL == props.link.URL &&
          <Link to={`/${node.main_parent.URL}/${node.sub_parent.URL}/${props.link.URL}/`} className={props.classname}>

                  <figure className="property-img">
                    <Img fixed={props.img} imgStyle={{transition:"all 0.5s"}} alt={`${capitalize(PageSlug)}${props.Title} - Orlando Reid`}/>
                  </figure>
                  <div className="property-title">
                    <h3>{props.Title}</h3>
                  </div>
          </Link>
          }
          </>
        })}
      
        </>
      )
      
      
  )
}

GetCTA.propTypes = {
  siteTitle: PropTypes.string,
  link: "",
}

GetCTA.defaultProps = {
  siteTitle: ``,
}

export default GetCTA
