import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Breadcrumb, Form } from 'react-bootstrap';
import Header2 from "../components/Header2/header2";
import DateFormat from "../components/format-date";
import Breadcrumbs from "../components/breadcrumbs";
import NewsMenus from "../components/news-menus";
import $ from "jquery"
import Img1 from "../images/img1.jpg";
import Img2 from "../images/img2.jpg";
import Img3 from "../images/img3.jpg";
import Img4 from "../images/img7.jpg";
import Img5 from "../images/img8.jpg";
import Img6 from "../images/img9.jpg";
import Subscribe from "../images/subscribe.png";
import { useStaticQuery, graphql } from "gatsby"
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import NewsletterForm from "../components/forms/newsletter-form";
import ImageTransform from "../components/common/ggfx-client/module/components/image-transform";
import { Logo } from '../components/icon';

const GET_news_NEWS = gql`
  query GetNews{	
	  newsEvents (sort:"News_Date:desc")  {
		id
	  	URL
        Title
        News_Date
		Banner_Image {
	      url
	    }
		Intro_Image {
	      url
	    }
		Select_Categories {
	      URL
	    }
		imagetransforms
      }
  }
`;


function StaticNews() {
//const { loading:recentnewsloading, error:recentnewserror, data:recentnews } = useQuery(GET_RECENT_NEWS);
const { loading:newsnewsloading, error:newsnewserror, data:newsnews } = useQuery(GET_news_NEWS);

  if (newsnewsloading) return (
    <div className="static-details gql-loading-screen">
        <Container>
        <Logo />
        <div className="gql-loading">Loading ...</div>
        </Container>
	</div>);
	
  return (
  	<>

        <div className="sub-wrapper">
			{/* header start */}
			<Header2 />
			{/* header end */}
			{/* content start */}
			<div className="news-content">
				<Container>
					{/* news head start */}
					<div className="news-head text-center">
						<Breadcrumbs/>
						<h1>News, Insights and Video from Orlando Reid</h1>
						<NewsMenus active="active" />						
					</div>
					{/* news head end */}
					{/* news banner start */}
					{newsnews.newsEvents.map((node, index) => {	
					
					let processedImages = JSON.stringify({});
					if (node?.imagetransforms?.Banner_Image_Transforms) {
						processedImages = node.imagetransforms.Banner_Image_Transforms;
					}

          let mycatslug = "news/"
          let mycatslugchk = node && node.Select_Categories && node.Select_Categories.length > 0 && node.Select_Categories[0].URL
          if(mycatslugchk){
              mycatslug = mycatslugchk+"/"
          }

					if(index === 0) {
					return (
						<>
							{node.Banner_Image !=null &&
							<Link to={`${mycatslug}${node.URL}/ `}>
							<div className="news-banner">
							<img src={node.Banner_Image.url} alt={`${node.Title} - Orlando Reid`}/>												

								<div className="news-caption text-center">
								<DateFormat date={node.News_Date} />
									<h2>{node.Title}</h2>
								</div>
							</div>
							</Link>
							}
						</>
					)
					}
					})}
					{/* news banner end */}
					{/* property-wrap start */}
					<Row className="news-items-wrap">
						{newsnews.newsEvents.map((item, index) => {
						let processedImages = JSON.stringify({});						
						if (item?.imagetransforms?.Intro_Image_Transforms) {
							processedImages = item.imagetransforms.Intro_Image_Transforms;
						}

	          let mycatslug = "news/"
	          let mycatslugchk = item && item.Select_Categories && item.Select_Categories.length > 0 && item.Select_Categories[0].URL
	          if(mycatslugchk){
	              mycatslug = mycatslugchk+"/"
	          }

						if(index !=0 && index <=3 && item.Intro_Image) {
						return (
						<Col md="6" className="col-lg-4">

							<div className="news-block text-center sample">
							<Link to={`${mycatslug}${item.URL}/ `}>
									<figure className="news-img">
									<img src={item.Intro_Image.url} alt={`${item.Title} - Orlando Reid`}/>					
									</figure>
									<div className="news-title">
										<DateFormat date={item.News_Date} />
										<h3>{item.Title}</h3>
									</div>
								</Link>
							</div>
						</Col>
						)
						}
						})}
						
					</Row>
					{/* property-wrap end */}
					{/* newsletter block start */}
					<Form className="newsletter-block banner-newsletter">
						
						<NewsletterForm />

						<div className="subscribe text-center d-md-flex align-items-md-center justify-content-md-center animate__animated">
							<img src={Subscribe} alt="img" />
							<span>
							<strong>Stay up to date </strong>
							with our new videos by
							<a href="https://www.youtube.com/channel/UCR0ob8Yam7B9tuG-81So3GA" target='_blank'> subscribing to our YouTube</a>
							</span>
						</div>
					</Form>
					{/* newsletter block end */}
					{/* property-wrap extend start */}
					<Row className="news-items-extend">
					{newsnews.newsEvents.map((item, index) => {
	          let mycatslug = "news/"
	          let mycatslugchk = item && item.Select_Categories && item.Select_Categories.length > 0 && item.Select_Categories[0].URL
	          if(mycatslugchk){
	              mycatslug = mycatslugchk+"/"
	          }
						if(index !=0 && index >=4) {
						return (
						<Col md="6" className="col-lg-4">
							<div className="news-block text-center">
							<Link to={`${mycatslug}${item.URL}/ `}>
									<figure className="news-img">
									<img src={item.Intro_Image.url} alt={`${item.Title} - Orlando Reid`}/>					
									</figure>
									<div className="news-title">
										<DateFormat date={item.News_Date} />
										<h3>{item.Title}</h3>
									</div>
								</Link>
							</div>
						</Col>
						)
						}
					})}						
					</Row>
					{/* property-wrap extend end */}
				</Container>
			</div>
			{/* content end */}
			{/* footer start */}
			{/* footer end */}
		</div>
		</>
    );
}


export default StaticNews