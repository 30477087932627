import { Container, Form } from 'react-bootstrap';
import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby"
import Grate from "../../images/google-rate.png";
import ReactMarkdown from "react-markdown/with-html"
import GetCTA from "../get-cta"
import ReactPlayer from "react-player";
import YouTube from '@u-wave/react-youtube';
import getVideoId from 'get-video-id';
import Img from 'gatsby-image';
import $ from "jquery";
import { navigate } from "@reach/router"
import { useLocation } from "@reach/router";
import { Close, VideoPlayButton, Search, VideoPause, Mute, UnMute } from '../icon';
import ReviewScore from "../reviewScore"
import Modal from 'react-bootstrap/Modal'
import Brochure from "../../components/forms/download-brochure"
import SalesForceForm from "../../components/forms/salesforce-form"
import CareersForm from "../forms/career";
import Breadcrumbs from "../breadcrumbs";
import { Helmet } from "react-helmet";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import {
  isIOS,
  isMobileOnly,
  isBrowser,
  isMobile
} from "react-device-detect";

const Banner = (props) => {

  const inputRef = React.useRef(null)

  const [show, setShow] = useState(false);

  const [modalshow, setModalShow] = useState(false);
  const [play, setPlayvideo] = useState(true);
  const [mute, setMute] = useState(true);

  const [controls, setVideoControls] = useState(false);

  const vidRef = useRef(null);
  const pausevideo = (ref) => {
    setPlayvideo(false)
    setVideoControls(false)

  }

  const playvideo = (ref) => {

    setPlayvideo(true)
    setVideoControls(false)
  }

  const showControls = () => {
    setVideoControls(true)
  }


  if (props.HTMLVideo != null && props.HTMLVideo != "") {
    var video_url = props.HTMLVideo.internal.description;
    video_url = video_url ? video_url.replace("File ", "").replace('"', '').replace('"', '') : '';
  }
  if (props.VideoURL != null) {
    var videoid = getVideoId(props.VideoURL);
  } else {
    var videoid = ''
  }

  const location = useLocation();
  const thePath = location.pathname
  const PageSlug = thePath.substring(thePath.lastIndexOf('/') + 1) + ' '
  const [showVideo, setShowVideo] = React.useState(false);
  const [brochureShow, setBrochureShow] = useState(false);

  const [showHTMLVideo, setHTMLVideo] = React.useState(false);
  const [, setPlay] = React.useState(false);
  const trackerVideo = (event) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Video Play Btn',
      'formType': event,
      'formId': 'Video Play',
      'formName': 'Video',
      'formLabel': 'Video'
    });
  }

  // search results page navigation
  useEffect(() => {
    let url = "/";
    $(".sales_btn").click(function () {
      var searcValue = $(".search_field_text").val();
      let str = searcValue.trim();
      let fixedStr = str?.replace(/\s+/g, ' ');
      if(fixedStr !== "") {
        url = "/property/for-sale/in-" + fixedStr + "/"
      }
      else {
        url = "/properties-for-sale-in-london-and-manchester/"
      }
      navigate(url);
    })

    $(".rent_btn").click(function () {
      var searcValue = $(".search_field_text").val();
      let str = searcValue.trim();
      let fixedStr = str?.replace(/\s+/g, ' ');
      if (fixedStr !== "") {
        url = "/property/to-rent/in-" + fixedStr + "/"
      } else {
        url = "/properties-to-rent-in-london-and-manchester/"
      }
      navigate(url);
    })
  }, []);
  // search results page navigation
  function gotohash(event) {
    var thishash = '#' + event;
    $('html, body').animate({
      scrollTop: $(thishash).offset().top - 150
    }, 2000);
  }

  var guide_url = '';

  if (props.InvestorsGuide && props.InvestorsGuide[0]) {
    guide_url = props.InvestorsGuide && props.InvestorsGuide[0].Upload_Guide.internal.description ? props.InvestorsGuide[0].Upload_Guide.internal.description.replace("File ", "").replace('"', '').replace('"', '') : '';
  }

  let processedImages = JSON.stringify({});
    if (props?.imagetransforms?.Banner_Image_Transforms) { 
            processedImages = props.imagetransforms.Banner_Image_Transforms;
        }
  const image_url = props.BannerImg && props.BannerImg.internal.description ? props.BannerImg.internal.description.replace("File ", "").replace('"', '').replace('"', '') : '';

  const imagename = (props.appId ? `${props.appId}` : 'articles') + '.Banner_Image.commonbanner';
  const siteUrl= process.env.GATSBY_SITE_URL
  return (
    <section className="banner">

      {thePath == '/' &&
        <Helmet>
          <script type="application/ld+json">{`{
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Orlando Reid",
            "alternateName": "Orlando Reid",
            "url": "https://www.orlandoreid.co.uk/",
            "logo": "https://ggfx-orlandoreid.s3.eu-west-2.amazonaws.com/i.prod/OR_Logo_115effa5b8.jpg",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "0207 627 3197",
              "areaServed": "GB",
              "availableLanguage": "en"
            },
                "aggregateRating": {
                  "@type": "AggregateRating",
                  "ratingValue": "4.7",
                  "reviewCount": "327"
                },
            "sameAs": [
              "https://www.facebook.com/pages/Orlando-Reid/163694923694097",
              "https://twitter.com/OrlandoReidUK",
              "https://www.instagram.com/orlandoreidlondon/?hl=en",
              "https://www.linkedin.com/company/orlando-reid/"
            ]
          }`}</script>
        </Helmet>
      }

      {props.VideoURL != null &&
        <>


          {showVideo &&
            <div className="video-close" onClick={() => { setPlay(false); setShowVideo(false) }}
            >
              <Close />
            </div>

          }
          {showHTMLVideo &&


            <div className="video-close" onClick={() => { setPlay(false); setHTMLVideo(false) }}
            >
              <Close />
            </div>

          }
          {showHTMLVideo && mute && isMobileOnly && isIOS &&

            <div ref={inputRef} className="video-close video-mute" onClick={() => { setMute(false) }}
            >
              <Mute />
            </div>
          }
          {showHTMLVideo && !mute && isMobileOnly && isIOS &&

            <div ref={inputRef} className="video-close video-mute" onClick={() => { setMute(true) }}
            >
              <UnMute />
            </div>
          }
          {showHTMLVideo && play && controls &&
            <div className="video-pause">
              <a href="#" onClick={() => pausevideo()}>  <VideoPause />
              </a>
            </div>
          }
          {showHTMLVideo && !play && controls && props.pagetitle !== "Home" &&
            <div className="video-playtrigger">
              <a href="#" onClick={() => playvideo()}>  <VideoPlayButton />
              </a>
            </div>
          
          }


          <div className="video-play" onClick={() => showControls()}>
            {showHTMLVideo &&
              <ReactPlayer autoplay ref={vidRef} onPlay={trackerVideo(props.Title)} url={video_url} controls={false}  muted={isMobile && isIOS ? mute : false} playsinline={true} playing={play} onEnded={() => { setHTMLVideo(false) }} width='100%' height='100%' />
            }
            {
              showVideo &&

              <YouTube
                video={videoid.id}
                autoplay
                showRelatedVideos={0}
                onEnd={() => { setPlay(false); setShowVideo(false) }}
                modestBranding={1}
                onPlaying={trackerVideo(props.Title)}
              />

            }

          </div>
        </>
      }

      {props.BannerImg != null &&
        <div className="banner-img">
          <ImageTransform imagesources={image_url} renderer="srcSet" imagename={imagename} attr={{ alt: (props.pagetitle == "Home" ? ""  :`${PageSlug}${props.Title.toLowerCase()} banner - Orlando Reid`)}} imagetransformresult={processedImages} id={props.AriticleId}/>
          
          {setModalShow && props.VideoURL &&
            <Modal dialogClassName="modal-full-video" show={modalshow} fullscreen={true} onHide={() => setModalShow(false)}>
            <div className="full-modal-close">
              <a href="javascript:;" onClick={()=>{setModalShow(false);}}><Close/></a>
            </div>
            <ReactPlayer autoplay ref={vidRef} url= {props.VideoURL} controls = {isIOS && isMobileOnly?true:false} muted={false} playsinline={true} playing={true} onEnded={() =>{setPlayvideo(false)}}  width='100%'height='100%' />
            </Modal>
          }
        </div>
      }
      <div className="banner-content text-center">
        <Container>
          {props.breadcrumb != "areaguides" && props.pagetitle != "Home" &&
            <Breadcrumbs />
          }
          {props.breadcrumb == "areaguides" && props.location == "London" &&
            <Breadcrumbs alias="london-area-guides" detailname={props.pagetile} />
          }
          {props.breadcrumb == "areaguides" && props.location == "Manchester" &&
            <Breadcrumbs alias="manchester-area-guides" detailname={props.pagetile} />
          }
          {props.Title != null &&
            <h1>{props.Title}</h1>
          }
          {props?.VideoURL && props?.VideoURL.trim() !== '' && props.pagetitle !== "Home" &&
            <a href="javascript:;" test onClick={()=>{setModalShow(true);playvideo();}}>  <VideoPlayButton /></a>
          }
          <div className="help-link">
            <ReactMarkdown source={props.Content} escapeHtml={false} />
          </div>

          <div className='instruct-cta-home'>
              {thePath == '/'&&
                  <Link to={"/instruct-us-now/" } className={"btn btn-white"}>Instruct us now</Link>
                }
          </div>
          <div className="btn-wrap  d-wrap-market">
            {
               props.Title == "Assisted Living Property Investment" &&
              <a  onClick={() => setBrochureShow(true)} className="btn btn-success">{props.CTALabel}</a>
                
            }
            {props.CTA1Hash == null &&
              <GetCTA link={props.CTAUrl} classname={`${thePath == "/" && "value-property-btn"} btn btn-success`} label={props.CTALabel} />
            }
            {props.CTALabel2 && props.CTA2Hash == null || props.CTA2Hash == ""  && props.CTALabel2 == "JOIN OUR INVESTORS CLUB" &&
              <Link to={"/property-services/investors/#register-for-investments"} className={"btn btn-success"}>{props.CTALabel2}</Link>
            }
            {props.CTA2Hash == null && props.CTALabel2 == "JOIN OUR INVESTORS CLUB" &&
              <Link to={"/property-services/investors/#register-for-investments"} className={`btn ${thePath == "/" ? "btn-success" : "btn-success" }`}>{props.CTALabel2}</Link>
            }
            {props.CTA1Hash != null &&

              <a onClick={() => gotohash(props.CTA1Hash)} className="btn btn-success">{props.CTALabel}</a>

            }
            {props.CTA2Hash != null && props.location === "London" && props.location === "Manchester" &&props.CTA2Hash != 'open-career-form' &&
              <a onClick={() => gotohash(props.CTA2Hash)} className="btn btn-white">{props.CTA2Hash}</a>
            }
            {props.CTA2Hash != null && props.CTA2Hash == 'open-career-form' &&
              <a onClick={() => setShow(true)} className="btn btn-white">{props.CTALabel2}</a>
            }
          </div>
          <Modal
            show={show}
            centered={true}
            onHide={() => setShow(false)}
            dialogClassName="modal-90w modal-form"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                Send us your cv
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CareersForm />
            </Modal.Body>
          </Modal>
          <Modal
            show={brochureShow}
            centered={true}
            onHide={() => setBrochureShow(false)}
            dialogClassName="modal-90w modal-form"
            aria-labelledby="example-custom-modal-styling-title"
			    >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
              Download A Brochure
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* <Brochure brochure={siteUrl + props.brochure?.publicURL} subject={props?.Title} /> */}
              <SalesForceForm className="popup" propertyName={"Assisted Living Property Investment"} retURL={process.env.GATSBY_SITE_URL+"/thank-you-assisted-living-brochure"}/>
            </Modal.Body>
			</Modal>
          {props.review == true &&
            <div className="google-rating d-md-flex align-items-md-center justify-content-md-center">
              <img src={Grate} alt="img" />
              <a href="/about-us/reviews"><ReviewScore /></a>
            </div>
          }
          {/* {thePath == '/' &&
            <h2>Orlando Reid has acquired Sheraton Law. <a href="/about-us/stories/press-release/orlando-reid-unites-with-sheraton-law-london-to-strengthen-the-agency-s-property-services">Read more</a></h2>
          } */}
         
          
        </Container>

        {props.inlinesearch == true &&
          <Form className="property-block bottom-search-block">
            <div className="field-wrap d-md-flex flex-md-wrap">
              <Form.Group controlId="formBasicEmail">
                <Form.Control className="search_field_text" type="text" placeholder="Your Postcode" />
              </Form.Group>
              <div className="btn-wrap">
                <Link className="btn btn-success" to="/property-valuation/?type=inperson">Sell</Link>
              </div>
              <div className="btn-wrap">
                <Link className="btn btn-success" to="/property-valuation/?type=inperson">Let</Link>
              </div>
            </div>
          </Form>
        }

      </div>
      {props.search == true &&
        <Form className="form-postcode">
          <Form.Group controlId="formBasicEmail">
            <Form.Control type="text" className="search_field_text" placeholder="Street, area or postcode" />
          </Form.Group>
          <button className="btn-search" type="submit">
            <Search />
          </button>
          <ul className="tolet-link d-flex">
            <li>
              <a href="javascript:void(0)" className="sales_btn">buy</a>
            </li>
            <li>
              <a href="javascript:void(0)" className="rent_btn">rent</a>
            </li>
          </ul>
        </Form>
      }
      {/* <PlayButton
              isOpen = { isPlay }
              isCloseFunction = { setPlay }
              videoId="PAh7OCbR5BQ"
              isAutoPlay={1}
            /> */}

    </section>

  )
}

Banner.propTypes = {
  siteTitle: PropTypes.string,
}

Banner.defaultProps = {
  siteTitle: ``,
}

export default Banner
