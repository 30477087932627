import React, { useState, useEffect } from 'react';
import CountUp from 'react-countup';
import $ from 'jquery'
const Counter = (props) => {
	
	const [isStart,setStart] = useState(false);
	useEffect(()=>{

	window.addEventListener('scroll', handleScroll,true);
    function handleScroll(e) {
    var counterdiv = $('.section-data');
if (counterdiv.length) {

var targetOffset = $(".section-data").offset().top;
		if (typeof window !== "undefined") {
			$(window).scroll(function(){
			    if ( $(window).scrollTop() > targetOffset ) {   
			        // Do stuffs here
			        setStart(true)

			    }
			});
		}

}
     const element = document.getElementsByClassName("data")[0];
     if(element) {
		 const	diff = Math.abs(element.offsetTop-element.offsetHeight+window.scrollY)
		 setStart(true)

	 }
    }
    return () =>{
    	
      window.removeEventListener('scroll',handleScroll);
    }
	},[])
	return (<React.Fragment>
		{
			isStart ?
				<React.Fragment>
					<strong>
						<CountUp
							end={props.start}
							start={0}
							prefix={props.prefix}
							duration={props.duration}
							suffix={props.sym}
							decimals={props.decimal}
						/>						
					</strong>

				</React.Fragment> : null
		}

	</React.Fragment>)

}

export default Counter;