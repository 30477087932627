import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import Modal from 'react-bootstrap/Modal'
import ImageTransform from "./common/ggfx-client/module/components/image-transform"
import Img from "gatsby-image"
import MemberForm from "./forms/member-form"
import "../components/PropertyManchesterTeam.scss"

const PropertyManchesterTeam = ({ ManchesterTitle, ManchesterTeam }) => {
 
  const [show, setShow] = useState(false);
  const [teamdetails, setTeamdetails] = useState(null);
  const [shareurl ,setShareurl] = useState("");
  function setManchester(props) {
    setTeamdetails(props)
    if (typeof window !== "undefined") {
      var pageURL = window.location.href ? window.location.href : '';
      setShareurl(pageURL)
  }}

  return (
    <div className="property-manchester">
      <h2>{ManchesterTitle}</h2>
      <div className="team-person-details">
        {ManchesterTeam && ManchesterTeam.map(team => {
            return (
               <div className="profile-details d-flex px-0">
                  {team.Staff_Image != null && (
                    <div>
                      <figure className="profile-img">
                        <Img fluid={team.Staff_Image.childImageSharp.fluid}/>
                      </figure>
                    </div>
              )}

              <div className="profile-desc">
                <span className="user-name">{team.Name}</span>
                <span className="designation">{team.Designation}</span>
                <ul className="d-flex">
                  {team.Phone && (
                    <li>
                      <a href={`tel:${team.Phone}`}>{team.Phone}</a>
                    </li>
                  )}
                  <li>
                    <a href="javascript:;" onClick={() =>{setShow(true)
                    setManchester(team)}
                   }>
                      Email
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          )
        })} </div>

        {teamdetails && 
                 <Modal
                    show={show}
                    centered={true}
                    onHide={() => setShow(false)}
                    dialogClassName="modal-90w modal-form"
                    aria-labelledby="example-custom-modal-styling-title"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="example-custom-modal-styling-title">
                        CONTACT - {teamdetails.Name}
                       
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <MemberForm
                        to_email_id={teamdetails.Email}
                        office="manchester"
                        formName={`Contact Person`}
                        page_url ={shareurl}
                      />
                    </Modal.Body>
                  </Modal>
}
      
    </div>
  )
}
export default PropertyManchesterTeam
