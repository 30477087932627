import React, { useState, useEffect } from "react";
import { useMatch } from "@reach/router"
import { Link } from "gatsby";
import { Button, Container, Row, Col, Form } from 'react-bootstrap';
import Gallery1 from "../images/gallery1.jpg";
import Fade from 'react-reveal/Fade';
import "animate.css/animate.css";
import ReactMarkdown from "react-markdown/with-html"
import Breadcrumbs from "../components/breadcrumbs";
// import ScrollAnimation from 'react-animate-on-scroll';

const AboutSection = (props)=>{
const detailmatch = useMatch("/about-us/our-people/:item/")
    if(detailmatch == null) {

  return (<React.Fragment>
  

      <div className='about-text landing-intro'>
      {/* <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true} > */}

        <Container>
          <div className="news-head text-center 456"  >
            {props.pagetitle !="Reviews" &&
              <Breadcrumbs/>
            }
            <h1>{props.Title}</h1>
            <ReactMarkdown source={props.Content} escapeHtml={false}/>            
          </div>          
        </Container>
        {/* </ScrollAnimation> */}
      </div>

      
  </React.Fragment>)
} else {
  return (
    <empty></empty>
    )
}

}

export default AboutSection;